import { graphql, PageProps } from "gatsby";
import React, { FormEvent, useContext, useState } from "react";
import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { IWithGoogleReCaptchaProps, withGoogleReCaptcha } from "react-google-recaptcha-v3";

import Layout from "../components/layout";
import SEO from "../components/seo";
import LangContext from "../context/LangContext";
import { ContactForm, Pages } from "../models";

interface Props {
  site: {
    siteMetadata: {
      pages: Pages;
      contact: ContactForm;
    };
  };
}

interface ContactFields {
  firstname: String;
  lastname: String;
  email: String;
  phone: String;
  subject: String;
  message: String;
  "g-recaptcha-response": String;
}

const Contact: React.FC<PageProps<Props> & IWithGoogleReCaptchaProps> = props => {
  const ctx = useContext(LangContext);
  const title = props.data.site.siteMetadata.pages.contact[ctx.lang];
  const contactLabels = props.data.site.siteMetadata.contact;
  const [inProgress, setInProgress] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    setInProgress(true);
    const result = await props.googleReCaptchaProps.executeRecaptcha("contact");
    const data: ContactFields = {
      // @ts-ignore
      firstname: event.target.firstname.value,
      // @ts-ignore
      lastname: event.target.lastname.value,
      // @ts-ignore
      email: event.target.email.value,
      // @ts-ignore
      phone: event.target.phone.value,
      // @ts-ignore
      subject: event.target.subject.value,
      // @ts-ignore
      message: event.target.message.value,
      "g-recaptcha-response": result,
    };
    axios
      .post("https://europe-west6-philosophe-296911.cloudfunctions.net/sendContactEmail", data, {
        headers: { Accept: "application/json" },
      })
      .then(i => {
        setSubmitted(true);
        setInProgress(false);
      });
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <div className="contact page-container">
        <h1>{title}</h1>
        <Container fluid={true}>
          <Row>
            <Col lg={5} className="contactForm">
              {/* <Alert variant="warning" style={{ textAlign: "center", fontWeight: 500, fontSize: 20 }}>
        Vacances d'été du 23 juin au 12 juillet. Toute l'équipe du Philosophe vous souhaite un bel été.
              </Alert> */}
              {submitted && (
                <Alert variant="success">{contactLabels.successMessage[ctx.lang]}</Alert>
              )}
              {!submitted && (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>{contactLabels.firstname[ctx.lang]}</Form.Label>
                    <Form.Control type="input" name="firstname" required={true} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{contactLabels.lastname[ctx.lang]}</Form.Label>
                    <Form.Control type="input" name="lastname" required={true} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{contactLabels.email[ctx.lang]}</Form.Label>
                    <Form.Control type="email" name="email" required={true} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{contactLabels.phone[ctx.lang]}</Form.Label>
                    <Form.Control type="tel" name="phone" required={true} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{contactLabels.subject[ctx.lang]}</Form.Label>
                    <Form.Control type="input" name="subject" required={true} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{contactLabels.message[ctx.lang]}</Form.Label>
                    <Form.Control as="textarea" name="message" required={true} />
                  </Form.Group>
                  <Button type="submit" variant="light">
                    {inProgress ? <Spinner animation="border" /> : contactLabels.send[ctx.lang]}
                  </Button>
                </Form>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default withGoogleReCaptcha(Contact);

// prettier-ignore
export const query = graphql`
  {
    site {
      siteMetadata {
        pages {
          contact { fr de it en }
        }
        contact {
          firstname { fr de it en }
          lastname { fr de it en }
          email { fr de it en }
          phone { fr de it en }
          subject { fr de it en }
          message { fr de it en }
          send { fr de it en }
          successMessage { fr de it en }
        }
      }
    }
  }
`;
